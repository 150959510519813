#services {
  background-color: #f6f8fb;
}
.service-box {
  padding: 2rem;
  border: 1px solid #eef1f5;
  // box-shadow: 2px 2px 4px #e9ebef;
  margin-bottom: 1rem;
  background: white;
  .service-icon {
    font-size: 2rem;
    color: #FFBC01;
  }
 
  /* On screens that are 600px or less, set the background color to olive */
 @media screen and (max-width: 700px) {
  .card-img-top{
    height: 200px !important;
}

 }
  
  .service-body {
    .service-title {
      font-size: 1.3rem;
      color: #4e4e4e;
      margin-bottom: 1.2rem;
    }
    .service-description {
      font-size: 0.9rem;
      color: #6f6f6f;
    }
  }
}
